import parse from 'html-react-parser'

export const changeColor = (text,indexElement) => {
    const arrayText = text.split(' ');
    let oryText = "";
    let indexE = indexElement;

    arrayText.forEach((element,index) => {
        
        if(index === arrayText.length-indexE){
            oryText += " <span>"+ element+"</span>"
            indexE --;
        }
        else{
        oryText += " "+ element
        }
    });
    const textReturn = parse(oryText)
    return textReturn;

}